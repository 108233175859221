// Custom Theming for Angular Material
@use 'sass:map'; // <-- Add this
@use 'sass:color';
@use '@angular/material' as mat;

@include mat.core();
@import 'styles/poppins.scss';

$Homepage-primary: mat.define-palette(mat.$indigo-palette);

// Define your custom accent color
$custom-accent-color: #53DAFF; // replace with your color

$custom-primary-color: #FF7853; // Your desired color
$custom-primary-palette: (
  50: color.adjust($custom-primary-color, $lightness: 40%),
  100: color.adjust($custom-primary-color, $lightness: 30%),
  200: color.adjust($custom-primary-color, $lightness: 20%),
  300: color.adjust($custom-primary-color, $lightness: 10%),
  400: color.adjust($custom-primary-color, $lightness: 5%),
  500: $custom-primary-color,
  600: color.adjust($custom-primary-color, $lightness: -5%),
  700: color.adjust($custom-primary-color, $lightness: -10%),
  800: color.adjust($custom-primary-color, $lightness: -20%),
  900: color.adjust($custom-primary-color, $lightness: -30%),
  A100: color.adjust($custom-primary-color, $lightness: 40%),
  A200: color.adjust($custom-primary-color, $lightness: 20%),
  A400: color.adjust($custom-primary-color, $lightness: -10%),
  A700: color.adjust($custom-primary-color, $lightness: -20%),
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: #000000,
    A200: #000000,
    A400: white,
    A700: white,
  ),
  default: $custom-primary-color,
  lighter: color.adjust($custom-primary-color, $lightness: 20%),
  darker: color.adjust($custom-primary-color, $lightness: -20%)
);

$custom-accent-palette: (
  50: color.adjust($custom-accent-color, $lightness: 40%),
  100: color.adjust($custom-accent-color, $lightness: 30%),
  200: color.adjust($custom-accent-color, $lightness: 20%),
  300: color.adjust($custom-accent-color, $lightness: 10%),
  400: color.adjust($custom-accent-color, $lightness: 5%),
  500: $custom-accent-color,
  600: color.adjust($custom-accent-color, $lightness: -5%),
  700: color.adjust($custom-accent-color, $lightness: -10%),
  800: color.adjust($custom-accent-color, $lightness: -20%),
  900: color.adjust($custom-accent-color, $lightness: -30%),
  A100: color.adjust($custom-accent-color, $lightness: 40%),
  A200: color.adjust($custom-accent-color, $lightness: 20%),
  A400: color.adjust($custom-accent-color, $lightness: -10%),
  A700: color.adjust($custom-accent-color, $lightness: -20%),
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: #000000,
    A200: #000000,
    A400: white,
    A700: white,
  ),
  default: $custom-accent-color,
  lighter: color.adjust($custom-accent-color, $lightness: 20%),
  darker: color.adjust($custom-accent-color, $lightness: -20%)
);


$custom-typography: mat.define-typography-config($font-family: 'Poppins, "Helvetica Neue", sans-serif'
  );

$Homepage-theme: mat.define-light-theme((color: (primary: $custom-primary-palette,
        accent: $custom-accent-palette,
      ),
      typography: $custom-typography));

@include mat.all-component-themes($Homepage-theme);

/* You can add global styles to this file, and also import other style files */

:root {
  --primary: #ffffff;
  --offset: background-color: rgb(249 250 251/var(--tw-bg-opacity));
  --toolbar: #999999;
  --primary-dark: #999999;
  --primary-light: #ffffff;
  --accent: #FF7853;
  --primary-text: black;
  --contrast-text: white;
  --accent-text: black;
  --margin: 2em;
  --max-width: 1400px;
  --mdc-filled-text-field-label-text-font: Poppins, "Helvetica Neue", sans-serif;
  --toolbar-height: 72px;
  --mat-toolbar-mobile-height: 72px;
  --mat-toolbar-standard-height: 72px;
  --toolbar-color: #333;
  --toolbar-height: 72px;

  @media screen and (max-width: 600px) {
    --margin: 1em;
  }
}

.mat-typography {
  font-family: Poppins, "Helvetica Neue", 'sans-serif' !important;
}

.mat-button,
.mat-tab-label,
button,
.mat-table,
.mat-label

/*... and any other Material component you are using */
  {
  font-family: Poppins, "Helvetica Neue", 'sans-serif' !important;
}

.dark-dialog-container {
  background-color: black;
}

.mat-mdc-row,
.mdc-data-table__content {
  font-family: Poppins, "Helvetica Neue", sans-serif !important;
}

html,
body {
  font-family: Poppins, "Helvetica Neue", sans-serif;
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--primary);
}

.cdk-overlay-backdrop {
  background: rgba(0, 0, 0, 0.9);
}

// Initially set the backdrop to transparent
.mat-dialog-container::ng-deep .cdk-overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.9);
}

// Define a keyframe for the fade-in animation
@keyframes fadeIn {
  from {
    background-color: transparent;
  }

  to {
    background-color: rgba(0, 0, 0, 0.9); // or your desired final backdrop color
  }
}

// Define a keyframe for the fade-out animation
@keyframes fadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.9); // or your desired final backdrop color
  }

  to {
    background-color: transparent;
  }
}

// Apply the animations to the backdrop
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  animation: fadeIn 0.5s; // Adjust the time as needed
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-exiting {
  animation: fadeOut 0.5s; // Adjust the time as needed
}

.max-width-container {
  max-width: var(--max-width);
  width: calc(100% - 2 * var(--margin));
}

.flex-container {
  display: flex;
  justify-content: center;
}

a {
  color: var(--accent);
}

a:hover,
button:hover {
  opacity: 0.7;
}

.mat-sidenav-container,
.mat-sidenav-content,
.mat-tab-body-content {
  transform: none !important;
}

.text-align-center {
  text-align: center;
}

.content-header {
  margin: 0 var(--margin);
  font-size: 2em;
  font-style: normal;
  line-height: 2em;
  font-weight: 400;
  text-align: center;
}

.secondary-header {
  font-size: 2em;
  color: var(--accent);
  font-style: normal;
  line-height: initial;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
}

.mat-toolbar-row {
  padding: 0 !important;
  justify-content: center;
}