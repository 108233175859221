@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url(../assets/fonts/Poppins-Black.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: url(../assets/fonts/Poppins-BlackItalic.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url(../assets/fonts/Poppins-Bold.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: url(../assets/fonts/Poppins-BoldItalic.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url(../assets/fonts/Poppins-ExtraBold.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: url(../assets/fonts/Poppins-ExtraBoldItalic.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url(../assets/fonts/Poppins-ExtraLight.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: url(../assets/fonts/Poppins-ExtraLightItalic.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url(../assets/fonts/Poppins-Italic.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url(../assets/fonts/Poppins-Light.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: url(../assets/fonts/Poppins-LightItalic.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(../assets/fonts/Poppins-Medium.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: url(../assets/fonts/Poppins-MediumItalic.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(../assets/fonts/Poppins-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url(../assets/fonts/Poppins-SemiBold.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: url(../assets/fonts/Poppins-SemiBoldItalic.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url(../assets/fonts/Poppins-Thin.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    src: url(../assets/fonts/Poppins-ThinItalic.woff2) format('woff2');
}
